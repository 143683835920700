import React from "react";
import Tester from "./Tester";
import ApiDocs from "./ApiDocs";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import NewsletterSignup from "./NewsletterSignup";

const App = () => {
  return (
    <BrowserRouter>
      <header className="header">
        <div className="logo-container">
          <Link to="/#top" className="logo">
            <div className="logo-wrapper">
              <svg
                width="46"
                viewBox="0 0 46 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="logo_svg"
              >
                <path
                  d="M33.3983 0L22.7797 10.6186L12.161 0L0 12.161L22.7797 34.9407L33.3983 24.322L45.5593 12.161L33.3983 0ZM33.3983 2.9661L42.5339 12.1017H24.322L33.3983 2.9661ZM22.7797 31.9153L2.9661 12.161L12.161 2.9661L21.3559 12.161L31.9746 22.7797L22.7797 31.9153Z"
                  fill="#ed2324"
                ></path>
              </svg>
            </div>

            <h1 className="logo-title">htaccess tester</h1>
          </Link>
        </div>
        <div className="tweet-container">
          <p>Like our tool?</p>
          <a
            href="https://twitter.com/intent/tweet?text=Have%20you%20already%20seen%20%40madewithlove%E2%80%99s%20%23htaccess%20tester%20tool%20to%20test%20your%20rewrite%20rules%3F%20&url=https%3A%2F%2Fhtaccess.madewithlove.com%2F"
            className="link-button margin-left link-button__adaptive"
          >
            Say thanks with a Tweet
          </a>
        </div>
      </header>

      <Routes>
        <Route path="/docs" element={<ApiDocs />} />
        <Route path="*" element={<Tester />} />
      </Routes>

      <NewsletterSignup />

      <footer className="footer">
        <div className="footer-container">
          <div className="footer__links">
            <h3>Links</h3>

            <Link to="/docs#top">
              <span className="link">API documentation</span>
            </Link>

            <a href="https://madewithlove.com/?ref=htaccess">
              <span className="link">About us</span>
            </a>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://madewithlove.com/blog/?ref=htaccess"
            >
              <span className="link">Our blog</span>
            </a>
          </div>
          <div className="footer__contribute">
            <h3>About Htaccess Tester</h3>
            <div className="disclaimer">
              <p>
                Htaccess tester is a valuable tool for software engineers to
                validate and test their htacces rewriting rules. Htaccess is a
                configuration file Apache web servers use to control and modify
                the server's behavior. It includes directives such as
                RewriteCond and RewriteRule that enable users to define URL
                rewriting and redirection rules. With our Htaccess tester,
                developers can input their htaccess codes and examine how they
                will affect the server's behavior. This includes rules like
                RedirectMatch for specifying pattern-based URL redirects, which
                can be essential for managing website migrations or
                restructuring.
                <br /> <br />
                Additionally, the Htaccess tester supports HTML redirects,
                allowing users to redirect visitors from one web page to
                another. They can also test the validity of HTTP-related
                directives in htacces files, ensuring that the server
                communicates correctly with the client.
                <br />
                <br />
                For any feature requests, <br />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/madewithlove/htaccess.madewithlove.com/issues/new/choose"
                  className="disclaimer-link"
                >
                  <span className="link">please comment</span>
                </a>{" "}
                on our public repository.
              </p>
            </div>
          </div>
          <div className="footer-tweet-container">
            <p>Like our tool?</p>
            <a
              href="https://twitter.com/intent/tweet?text=Have%20you%20already%20seen%20%40madewithlove%E2%80%99s%20%23htaccess%20tester%20tool%20to%20test%20your%20rewrite%20rules%3F%20&url=https%3A%2F%2Fhtaccess.madewithlove.com%2F"
              className="link-button footer-tweet-button"
            >
              Say thanks with a Tweet
            </a>
          </div>
        </div>
        <p className="footer__copyright">
          <a href="https://madewithlove.com/?ref=htaccess">madewithlove</a> is a{" "}
          <span className="strong">remote-first</span> company from Belgium
          building products since 2008 with love, a lot of it. |{" "}
          <a href="https://madewithlove.com/cookie-policy/?ref=htaccess">
            Cookie Policy
          </a>
          |{" "}
          <a href="https://madewithlove.com/privacy-policy/?ref=htaccess">
            Privacy Policy
          </a>
        </p>
      </footer>
    </BrowserRouter>
  );
};

export default App;
